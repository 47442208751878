const ATTRIBUTE_KEY_LENGTH_LIMIT = 128;
const ATTRIBUTE_STRING_VALUE_LIMIT_DEFAULT = 1024;
const ATTRIBUTE_STRING_VALUE_LIMIT_MAX = 10000;
const ATTRIBUTE_ARRAY_LENGTH_LIMIT_DEFAULT = 1000;
const ATTRIBUTE_ARRAY_LENGTH_LIMIT_MAX = 10000;
const ATTRIBUTE_COUNT_LIMIT_DEFAULT = 128;
const ATTRIBUTE_COUNT_LIMIT_MAX = 1000;
const defaultSpanAttributeLimits = {
  attributeStringValueLimit: ATTRIBUTE_STRING_VALUE_LIMIT_DEFAULT,
  attributeArrayLengthLimit: ATTRIBUTE_ARRAY_LENGTH_LIMIT_DEFAULT,
  attributeCountLimit: ATTRIBUTE_COUNT_LIMIT_DEFAULT
};
const defaultResourceAttributeLimits = {
  attributeStringValueLimit: Infinity,
  attributeArrayLengthLimit: Infinity,
  attributeCountLimit: Infinity
};
export { ATTRIBUTE_ARRAY_LENGTH_LIMIT_DEFAULT, ATTRIBUTE_ARRAY_LENGTH_LIMIT_MAX, ATTRIBUTE_COUNT_LIMIT_DEFAULT, ATTRIBUTE_COUNT_LIMIT_MAX, ATTRIBUTE_KEY_LENGTH_LIMIT, ATTRIBUTE_STRING_VALUE_LIMIT_DEFAULT, ATTRIBUTE_STRING_VALUE_LIMIT_MAX, defaultResourceAttributeLimits, defaultSpanAttributeLimits };