import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IpInfo } from '../01-models/IpInfo';

@Injectable({
  providedIn: 'root'
})
export class IpwhoisService {

  httpClient = inject(HttpClient);

  constructor() { }

  async getIpWhois() {
    const myIp = await lastValueFrom(this.getMyIp());
    return lastValueFrom(this.httpClient.get<IpInfo>(`https://ipwhois.app/json/${myIp.ip}`));
  }

  getMyIp() {
    return this.httpClient.get<IpInfo>('https://ipwhois.app/json/');
  }
}
