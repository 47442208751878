<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="presence-list" data-cy="tab-button-presence-list">
      <ion-icon name="time"></ion-icon>
      <ion-label>{{ 'TABS.PRESENCES' | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="service-sv" data-cy="tab-button-service-sv">
      <ion-icon name="calendar"></ion-icon>
      <ion-label>{{ 'TABS.SERVICES_SV' | translate }}</ion-label>
    </ion-tab-button>
    @if (user.role === 'Administrador') {
      <ion-tab-button tab="ticket-list" data-cy="tab-button-ticket-list">
        <ion-icon name="warning"></ion-icon>
        <ion-label>Tickets</ion-label>
      </ion-tab-button>
    }
    
  </ion-tab-bar>
</ion-tabs>
