import { RequestTracker } from './request-tracker.js';
import getAbsoluteUrl from './url-helpers.js';
function createStartContext(startTime, input, init, baseUrl) {
  const inputIsRequest = isRequest(input);
  const url = inputIsRequest ? input.url : String(input);
  const method = !!init && init.method || inputIsRequest && input.method || 'GET';
  return {
    url: getAbsoluteUrl(url, baseUrl),
    method,
    startTime,
    type: 'fetch'
  };
}
function isRequest(input) {
  return !!input && typeof input === 'object' && !(input instanceof URL);
}
function isHeadersInstance(input) {
  return !!input && typeof input === 'object' && input instanceof Headers;
}
function createFetchRequestTracker(global, clock) {
  const requestTracker = new RequestTracker();
  const originalFetch = global.fetch;
  global.fetch = function fetch(input, init) {
    const startContext = createStartContext(clock.now(), input, init, global.document && global.document.baseURI);
    const {
      onRequestEnd,
      extraRequestHeaders
    } = requestTracker.start(startContext);
    // Add the headers to the `init` received from the caller
    const modifiedParams = mergeRequestHeaders(input, init, extraRequestHeaders);
    return originalFetch.call(this, modifiedParams[0], modifiedParams[1]).then(response => {
      onRequestEnd({
        status: response.status,
        endTime: clock.now(),
        state: 'success'
      });
      return response;
    }).catch(error => {
      onRequestEnd({
        error,
        endTime: clock.now(),
        state: 'error'
      });
      throw error;
    });
  };
  return requestTracker;
}
function mergeRequestHeaders(input, init, extraRequestHeaders) {
  if (!extraRequestHeaders) return [input, init];
  const extraHeaders = extraRequestHeaders.reduce((headers, current) => ({
    ...headers,
    ...current
  }), {});
  if (isRequest(input) && (!init || !init.headers)) {
    mergeInputRequestHeaders(extraHeaders, input);
  } else {
    init = mergeInitRequestHeaders(extraHeaders, init);
  }
  return [input, init];
}
function mergeInputRequestHeaders(extraRequestHeaders, input) {
  for (const [name, value] of Object.entries(extraRequestHeaders)) {
    if (!input.headers.has(name)) {
      input.headers.set(name, value);
    }
  }
}
function mergeInitRequestHeaders(extraRequestHeaders, init) {
  if (!init) init = {};
  if (isHeadersInstance(init.headers)) {
    for (const [name, value] of Object.entries(extraRequestHeaders)) {
      if (!init.headers.has(name)) {
        init.headers.set(name, value);
      }
    }
    return init;
  } else {
    return {
      ...init,
      headers: {
        ...extraRequestHeaders,
        ...init.headers
      }
    };
  }
}
export { createFetchRequestTracker as default };