import { HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { LoadingService } from 'src/app/02-services';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService);
  //Excluir peticiones a bugsnag
  if (req.url.includes('bugsnag') || req.url.includes('SyncronizacionKflowTablasResultado')) {
    return next(req);
  }

  // mostrar loading solo en peticiones POST, PUT y DELETE
  if (['POST', 'PUT', 'DELETE'].includes(req.method)) {
    loadingService.present();
  }
  return next(req).pipe(tap(event => {
    if (event.type === HttpEventType.Response) {
      loadingService.closeLoading();
    }
  }));
};
