import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../02-services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    private router: Router
  ) {}

  canActivate(): boolean {    
    return this.authService.isAuthenticated();
  }    
}
