import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, combineLatest, forkJoin, Observable, switchMap, take, throwError } from "rxjs";
import { ConnectionStatus, NetworkService } from "../../02-services/network.service";
import { ServerPingService } from "../../02-services/server-ping.service";

@Injectable({
    providedIn: "root",
})
export class OfflineInterceptor {
    constructor(
        private networkService: NetworkService,
        private severPingService: ServerPingService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!req.url.includes('ping')) {
        if (req.url.includes('api')) {
            return combineLatest({
                networkServicelineIsOn: this.networkService.isConnected, // Esta variable se refiere
                lineModeisOff: this.networkService.lineModeisOff,
                ping: this.severPingService.getStatePing()
              }).pipe(
                take(1),
                switchMap(({ networkServicelineIsOn, lineModeisOff, ping }) => {
                  /*if (!ping) {
                    return throwError(() => new HttpErrorResponse({
                      error: 'Server is not available',
                      status: 0,
                      statusText: 'Server is not available'
                    }));
                  }*/
                  if (networkServicelineIsOn === ConnectionStatus.Offline || lineModeisOff) {
                    return throwError(() => new HttpErrorResponse({
                      error: 'No internet connection or offline mode activated',
                      status: 0,
                      statusText: 'Offline Mode'
                    }));
                  }
                  return next.handle(req);
                }),
                catchError((error: HttpErrorResponse) => {
                  return throwError(() => error);
                })
              );
        } else {
            return next.handle(req);
        }
    }
    return next.handle(req);
  }
    
}