// src/environments/firebase-config.ts

export const firebaseConfig = {
    apiKey: "AIzaSyBrJyvIsALnE9rwFV7G8NOR8HUO0DtX5jQ",  // Extraído de "current_key"
    authDomain: "kflowapp.firebaseapp.com",             // Firebase usa "project_id".firebaseapp.com como authDomain
    projectId: "kflowapp",                               // Extraído de "project_id"
    storageBucket: "kflowapp.appspot.com",               // Extraído de "storage_bucket"
    messagingSenderId: "68278333551",                    // Extraído de "project_number"
    appId: "1:68278333551:android:4b0808165fbbe1412e5d9a" // Extraído de "mobilesdk_app_id"
};

