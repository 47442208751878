import { ErrorHandler, inject, Injectable } from "@angular/core";
import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { lastValueFrom } from "rxjs";
import { AuthService, UserService } from "src/app/02-services";
import { IpwhoisService } from "src/app/02-services/ipwhois.service";
import { environment } from "src/environments/environment";

@Injectable({providedIn: 'root'})
export class BugsnagErrorHandler implements ErrorHandler {

    authService = inject(AuthService);
    ipwhoisService = inject(IpwhoisService);

  async handleError(error: any) {
    const user = this.authService.getUser();
    if (user) {
      Bugsnag.setUser(user.id.toString() || 'Desconocido', user.email, user.userName || 'Desconocido');
    }
    try {
      if (environment.production) {
        const ipInfo = await this.ipwhoisService.getIpWhois();
        Bugsnag.addMetadata('IpInfo', ipInfo);
        Bugsnag.notify(error);
      } else {
        console.error(error);
      }
    } catch (err) {
        Bugsnag.notify(error);
    }
  }


}