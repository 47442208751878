import { createNoopClient, createClient, InMemoryQueue } from '@bugsnag/core-performance';
import createFetchDeliveryFactory from '@bugsnag/delivery-fetch-performance';
import { createFetchRequestTracker, createXmlHttpRequestTracker } from '@bugsnag/request-tracker-performance';
import { FullPageLoadPlugin } from './auto-instrumentation/full-page-load-plugin.js';
import { NetworkRequestPlugin } from './auto-instrumentation/network-request-plugin.js';
import { ResourceLoadPlugin } from './auto-instrumentation/resource-load-plugin.js';
import { RouteChangePlugin } from './auto-instrumentation/route-change-plugin.js';
import createBrowserBackgroundingListener from './backgrounding-listener.js';
import createClock from './clock.js';
import { createSchema } from './config.js';
import { createNoopRoutingProvider, createDefaultRoutingProvider } from './default-routing-provider.js';
import idGenerator from './id-generator.js';
import createOnSettle, { createNoopOnSettle } from './on-settle/index.js';
import makeBrowserPersistence from './persistence.js';
import createResourceAttributesSource from './resource-attributes-source.js';
import { createSpanAttributesSource } from './span-attributes-source.js';
import { WebVitals } from './web-vitals.js';
let onSettle;
let DefaultRoutingProvider;
let BugsnagPerformance;
if (typeof window === 'undefined' || typeof document === 'undefined') {
  onSettle = createNoopOnSettle();
  DefaultRoutingProvider = createNoopRoutingProvider();
  BugsnagPerformance = createNoopClient();
} else {
  const backgroundingListener = createBrowserBackgroundingListener(window);
  const spanAttributesSource = createSpanAttributesSource(document);
  const clock = createClock(performance, backgroundingListener);
  const persistence = makeBrowserPersistence(window);
  const resourceAttributesSource = createResourceAttributesSource(navigator, persistence);
  const fetchRequestTracker = createFetchRequestTracker(window, clock);
  const xhrRequestTracker = createXmlHttpRequestTracker(XMLHttpRequest, clock, document);
  const webVitals = new WebVitals(performance, clock, window.PerformanceObserver);
  onSettle = createOnSettle(clock, window, fetchRequestTracker, xhrRequestTracker, performance);
  DefaultRoutingProvider = createDefaultRoutingProvider(onSettle, window.location);
  BugsnagPerformance = createClient({
    backgroundingListener,
    clock,
    resourceAttributesSource,
    spanAttributesSource,
    deliveryFactory: createFetchDeliveryFactory(window.fetch, clock, backgroundingListener),
    idGenerator,
    schema: createSchema(window.location.hostname, new DefaultRoutingProvider()),
    plugins: (spanFactory, spanContextStorage) => [onSettle, new FullPageLoadPlugin(document, window.location, spanFactory, webVitals, onSettle, backgroundingListener, performance),
    // ResourceLoadPlugin should always come after FullPageLoad plugin, as it should use that
    // span context as the parent of it's spans
    new ResourceLoadPlugin(spanFactory, spanContextStorage, window.PerformanceObserver), new NetworkRequestPlugin(spanFactory, spanContextStorage, fetchRequestTracker, xhrRequestTracker), new RouteChangePlugin(spanFactory, window.location, document)],
    persistence,
    retryQueueFactory: (delivery, retryQueueMaxSize) => new InMemoryQueue(delivery, retryQueueMaxSize)
  });
}
const BugsnagPerformance$1 = BugsnagPerformance;
export { DefaultRoutingProvider, BugsnagPerformance$1 as default, onSettle };